* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background: #161920 !important;
  color: #fff !important;
}
p {
  margin-bottom: 0rem !important;
}
html {
  --cursor-color: RED;
}

html.dark-mode {
  --cursor-color: #fff;
}

::-webkit-scrollbar {
  display: none;
}
