/* Extra Small Screens (phones) */
@media screen and (max-width: 600px) {
  .responsive-text {
    font-size: 1.5rem;
  }
  .rs-banner img {
    max-width: 100%;
    height: auto !important;
    position: relative !important;
    left: 0 !important;
  }
  .tool-tip-cus-left {
    left: 0 !important;
  }
  .banner {
    background-repeat: no-repeat;
    align-items: center;
    justify-content: center;
    color: #fff;
    height: auto !important;
    display: block;
    padding-bottom: 1rem;
  }
  .mainPage {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .bannerContent h4 {
    font-size: 34px !important;
  }

  .sqdName {
    font-size: 39px !important;
  }
  .main-des {
    font-size: 12px;
  }

  /* --------Footer--------- */
  .footer-main {
    padding: 3px !important;
  }
  .ringDiv {
    position: absolute;
    float: right;
    width: auto;
    right: 0;
    top: 0%;
  }
  .tool-tip-cus {
    /* display: inline-flex; */
    /* flex-direction: column; */
    right: 0px !important;
  }
  .download-image {
    justify-items: center !important;
  }
}
@media (max-width: 700px) {
  /* .banner .col-md-6 { */
  /* width: 100%; */
  /* } */
  .rs-banner img {
    position: relative !important;
  }
  .bannerContent h4 {
    font-size: 30px !important;
  }
  .sqdName {
    font-size: 40px;
  }
  .roleBasesection-left {
    text-align: start !important;
  }

  .main-des {
    font-size: 14px;
    width: 100% !important;
  }
}

@media (max-width: 800px) {
  /* width: 100%; */
  .banner {
    height: auto !important;
    text-align: center !important;
  }
  .rs-banner img {
    position: relative !important;
  }
  .bannerContent h4 {
    font-size: 30px !important;
  }
  .hero-header span {
    font-size: 30px !important;
  }
  .squad-mainDiv h4 {
    font-size: 30px !important;
  }
  .squadHighlight {
    font-size: 30px !important;
  }
  .tool-tip-cus-left {
    left: -18px !important;
  }
  .footer-main {
    display: flex;
  }
  .mainPage .row {
    display: block;
  }
  .download-image {
    justify-content: center !important;
  }
  .coursel-card-main {
    height: 400px !important;
  }

  .coursel-header .sqdName {
    font-size: 30px !important;
  }
  .main-des {
    font-size: 14px;
    width: 50%;
  }
}

/* @media (max-width: 1000px) {
  .banner .col-md-6 {
    width: 50%;
  }

  .rs-banner img {
    position: absolute !important;
  }
  .main-des {
    color: #a2a3a6;
    font-family: Plus Jakarta Sans, sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    width: 50%;
  }
} */

/* Small Screens (tablets) */
@media screen and (min-width: 701px) and (max-width: 999px) {
  .banner {
    height: auto !important;
    text-align: center !important;
  }
  .col-md-6 {
    width: 100%;
  }
  .coursel-card-main {
    height: 390px !important;
  }
  .main-des {
    width: 100%;
  }
  .rs-banner img {
    width: 702px;
    position: relative !important;
    bottom: 0;
    right: 0;
  }

  .img-fluid {
    display: block !important;
    margin: auto;
  }
}

/* Medium Screens (laptops) */
@media screen and (min-width: 961px) and (max-width: 1280px) {
  .responsive-text {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .logoVedio {
    height: 40rem;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1100px) {
  .rs-banner img {
    position: relative;
    max-width: 120%;
  }
}
@media screen and (max-width: 1530px) {
  .rs-banner img {
    width: 583px !important;
  }
}
/* Large Screens */
@media screen and (min-width: 1281px) and (max-width: 1920px) {
  .responsive-text {
    font-size: 3rem;
  }
}

/* Extra Large Screens */
@media screen and (min-width: 1921px) {
  .responsive-text {
    font-size: 3.5rem;
  }
}
@media screen and (max-width: 1110px) {
  .rs-banner img {
    width: 583px !important;
    bottom: -68px !important;
  }

  .dwnbtn {
    margin: auto;
  }
  .footer-main {
    padding: 60px 4rem !important;
  }
  .footer-main h5{
    font-size: 10px !important;
  }
  
  .dot{
    display: none;
  }
  .privacy{
    display: list-item;
  }
}
.rs-banner img {
  width: 702px;
  position: absolute;
  bottom: 10px;
  right: 0;
}

@media screen and (min-width: 1100px) and (max-width: 1300px) {
  .rs-banner img {
    bottom: 10px !important;
  }
}

@media screen and (min-width: 1821px) and (max-width: 2000px) {
  .rs-banner img {
    bottom: 0;
    position: absolute;
    right: 0;
    width: 989px !important;
  }
}
