.dwnbtn {
  width: fit-content;
  padding: 15px 20px;
  justify-content: center;
  gap: 10px;
  border-radius: 75px;
  background: #8261c2;
  color: #fff;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  display: flex;
}
.inheritCLasss {
  background: inherit;
  border: none;
  color: inherit;
}

.dwnldbtnb {
  padding: 13px 20px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 75px;
  background: #8261c2;
  color: #fff;
  text-align: center;
  /* 16_Semi */
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  width: 200PX;

  padding: 13px 24px;
  border-radius: 75px;
  border: none;
}
.dwnldbtnb2 {
  padding: 13px 20px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 75px;
  background: #8261c2;
  color: #fff;
  text-align: center;

  /* 16_Semi */
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  line-height: normal;
}
