.banner {
  background: url("../../Assets//Images/bannerbg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
  background-position: center;
  color: #fff;
  height: 100vh;
  display: block;
}
.dot {
  float: inline-end;
}
.coursel-header-content {
  position: relative;
}
.coursel-button {
  position: absolute;
  right: 0;
}
.roleBasesection-left {
  text-align: end;
  justify-content: space-around;
}
.fantacy-logo img{
height:  3rem;
}
.main-heading{
  background: linear-gradient(90deg, #8261c2 0, #35b795 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 24px;
}
.hero-header {
  margin-top: 120px;
}
.mainPage {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.bannerContent h4 {
  color: "#fff";
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: 118.4%;
}
.main-des {
  color: #a2a3a6;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
}
.sqdName {
  background: linear-gradient(90deg, #8261c2 0, #35b795 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 70px;
  font-style: normal;
  font-weight: 800;
  line-height: 118.4%;
}
.sqdName-mini{
  background: linear-gradient(90deg, #8261c2 0, #35b795 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.banner-des {
  color: var(--GreyScale-Grey, #a2a3a6);
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
}
.rs-banner img {
  width: 702px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.ls-banner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero-header span {
  color: var(--GreyScale-White, #fff);
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.hero-header p {
  color: var(--GreyScale-Grey, #a2a3a6);
  text-align: center;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
}
.main-card {
  display: flex;
  flex-direction: column;
}
.row {
  border-radius: 30px;
}
.duelImage {
  max-width: 100%;
}
.main-card .card-title {
  color: #d9d9d9;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.main-card .card-text {
  color: #a0a0a0;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.753px;
}
.main-card .card {
  background-color: transparent;
}
.accordation-container .accordion-button,
.accordion-button:not(.collapsed) {
  background: linear-gradient(270deg, #161920 44.39%, #1d3b39 123.59%);
  font-family: Plus Jakarta Sans, sans-serif;
  color: #fff;
  border: 0;
  display: flex;
  align-items: flex-start;
  gap: 15px;
}
.accordation-container .accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
  background: 0;
  background: linear-gradient(270deg, #161920 44.39%, #1d3b39 123.59%);
  color: #fff;
}
.accordation-container .accordion {
  --bs-accordion-bg: none;
}
.accordion-button.collapsed .accordion-icon::before {
  color: white;
}
.accordation-container .accordion-item {
  border: 0;
  border-bottom: 1px solid rgba(111, 128, 161, 0.4);
}
.accordation-container .accordion-button::after {
  filter: invert(1);
}
.squad-mainDiv {
  background-image: url("../../Assets/Images/squadBg.svg");
  align-items: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  gap: 3rem;
}
.squad-mainDiv h4 {
  color: var(--White, #fff);
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 58px;
  text-transform: capitalize;
}
.squadHighlight {
  color: var(--Green-Green, #36b696);
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 58px;
}
.squad-mainDiv p {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.slick-prev {
  position: absolute !important;
  right: 92px !important;
  top: -64px !important;
  width: auto !important;
  height: auto !important;
  left: unset !important;
}
.slick-next {
  display: block !important;
  position: absolute !important;
  right: 0 !important;
  top: -64px !important;
  width: auto !important;
  height: auto !important;
}

.coursel-card-main:hover {
  background: linear-gradient(
    90deg,
    rgba(130, 97, 194, 0.7) 0,
    rgba(53, 183, 149, 0.7) 100%
  );
}

.processsliding:hover .coursel-card-main {
  animation-name: slider;
  animation-duration: 4s;
}

@keyframes slider {
  0% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(-20vw);
  }

  100% {
    transform: translateX(0px);
  }
}
.coursel-card-main:hover svg path {
  fill: #fff;
}
.coursel-card-main:hover svg circle {
  fill: #fff;
}
.coursel-card-main:hover .hoverImage {
  fill: #35b795;
}
.coursel-card-main:hover svg {
  background: white;
  border-radius: 50%;
}
.coursel-card-main {
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 19px;
  border-radius: 25px;
  margin-right: 15px;
  background: #1e2329;
  margin-top: 20px;
  height: 290px;
}
.coursel-header {
  color: #fff;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.coursel-content {
  color: #a2a3a6;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
}
.coursel-card-main h5 {
  color: #fff;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.coursel-card-main p {
  border-top: 0.5px solid rgba(255, 255, 255, 0.4);
  color: rgba(255, 255, 255, 0.6);
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  padding-top: 20px;
}
.accordation-container {
  border-radius: 20px;
  background: linear-gradient(270deg, #161920 44.39%, #1d3b39 123.59%);
  padding: 3rem;
}
.accordation-container h3 {
  color: #fff;
  /* 20_Bold */
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.accordation-container p {
  color: rgba(255, 255, 255, 0.6);
  /* 16 Paragraph 1 */
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 187.5% */
}
.accordion-button:not(.collapsed) {
  box-shadow: none;
  margin-bottom: 1px solid rgba(96, 111, 140, 0.4) !important;
}
.ringDiv {
  position: relative;
}
.aboutCardSection {
  border-radius: 30px;
  border: 1px solid var(--Other-COlors-Gradient-BG, #161920);
  background: var(
    --gradient,
    linear-gradient(
      117deg,
      rgba(150, 150, 150, 0.2) 2.76%,
      rgba(1, 1, 1, 0.2) 93.37%
    )
  );
}
.tool-tip-cus {
  border-radius: 10.858px;
  border: 1.086px solid rgba(238, 238, 238, 0.38);
  background: #1c1c1c;
  position: absolute;
  right: -15px;
  padding: 20px;
  top: 60px;
}
.tool-tip-cus img {
  position: absolute;
  top: -12px;
  right: -16px;
}
.tool-tip-cus p {
  color: #fff;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ringDiv img {
  top: -11px;
}
.leftDiv img {
  top: -11px;
}
.tool-tip-cus-left {
  border-radius: 10.858px;
  border: 1.086px solid rgba(238, 238, 238, 0.38);
  background: #1c1c1c;
  position: relative;
  padding: 19px;
  top: -73px;
  width: 32%;
  left: -49px;
}
.tool-tip-cus-left p {
  color: #fff;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.tool-tip-cus-left img {
  position: absolute;
  top: -12px;
  right: -16px;
}
.tool-tip-cus-left h6 {
  color: #fff;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.logoVedio {
  border-radius: 4%;
}
.download-image {
  align-items: end;
}
