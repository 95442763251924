.NavbarMain {
  background-size: cover;
  background-repeat: no-repeat;
  border-bottom: 1px solid #606f8c;
}
.bg-body-tertiary {
  background-image: url("../../../Assets//Images/bannerbg.svg");
}
.NavbarMain .navbar-toggler-icon {
  filter: invert(1);
}
