.agreementMainDiv {
  background-color: var(--GreyScale-Background, #161920);
  margin: 0 5px;
  color: #fff;
  padding: 3rem 0rem;
}
.agreementMainDiv h4 {
  color: var(--GreyScale-White, #fff);
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.agreement-side {
  color: #fff;
  /* 18_Bold */
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}
.agreementMainDiv p {
  color: var(--GreyScale-Grey, #a2a3a6);
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 168.75% */
}
.main-content {
  border-radius: 25px;
  background: var(--GreyScale-Card-BG, #1e2329);
  display: flex;
  padding: 33px 40px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.card-header h3 {
  color: var(--GreyScale-White, #fff);
  text-align: center;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: center;
}
.card-header span {
  background: var(--Stroke, linear-gradient(90deg, #7f65c0 0, #3ab298 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  justify-content: center;
  display: flex;
}
.card-header p {
  color: var(--GreyScale-White, #fff);
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  justify-content: center;
  display: flex;
}
.card-body h4,
h6 {
  color: var(--GreyScale-White, #fff);
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.card-body span {
  background: var(--Stroke, linear-gradient(90deg, #7f65c0 0, #3ab298 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.card-body p {
  color: var(--GreyScale-Grey, #a2a3a6);
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 2rem !important;
}

.footer-main {
  padding: 60px 100px;
  border-top: 1px solid #606f8c;
  background: var(--GreyScale-Card-BG, #1e2329);
}
.footer-main h5 {
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
  padding: 0;
  margin: 0;
}
.dot {
  height: 6px;
  width: 6px;
  background-color: #fff;
  border-radius: 50%;
  left: 0;
  position: relative;
}
.privacy-table {
  background-color: #161920;
  --bs-table-bg: #161920;
  --bs-table-color: #fff;
  border-radius: 20px;
  font-family: Plus Jakarta Sans, sans-serif;
}
.table > :not(caption) > * > * {
  border: 3px solid #7f65c0 ;
}
.table th {
  background: var(--Stroke, linear-gradient(90deg, #7f65c0 0, #3ab298 100%));
}
.card-body b {
  font-weight: bold;
  font-weight: 900;
  color: white;
  font-family: Plus Jakarta Sans, sans-serif;
}
.card-body li {
  margin-bottom: 1rem;
}
.card-body a {
  color: #6187af;
  font-weight: bold;
}
